import AsyncStorage from '@react-native-async-storage/async-storage';
import globalStore from './services/global.state';
import {http, lottoHttp, VERSION_CODE} from '@utils';
import envConfig from './utils/env.config';
import DeviceInfo from 'react-native-device-info';

export const setVisitor = (id: string) => {
  AsyncStorage.getItem('visitor').then(res => {
    globalStore.visitor = res || id;
    AsyncStorage.setItem('visitor', res || id);
  });
};

export const setToken = () => {
  globalStore.token = localStorage.getItem('token');
};

export const setUserInfo = () => {
  AsyncStorage.getItem('user').then(res => {
    if (res) {
      globalStore.userInfo = JSON.parse(res);
    }
  });
};

export const setFCMToken = async (token: string) => {
  await AsyncStorage.setItem('FCMToken', token);
};

// 初始化推送
export const initPush = async () => {
  const ReactMoE = require('react-native-moengage').default;
  const Moengage = require('react-native-moengage');
  const {MoEInitConfig, MoEPushConfig, MoEngageLogConfig, MoEngageLogLevel} =
    Moengage;
  const moEInitConfig = new MoEInitConfig(
    MoEPushConfig.defaultConfig(),
    new MoEngageLogConfig(MoEngageLogLevel.VERBOSE, true),
  );
  ReactMoE.initialize(envConfig.moengageAppId, moEInitConfig);

  const firebase = require('@react-native-firebase/app').default;
  const messaging = require('@react-native-firebase/messaging').default;
  const status = await firebase.utils().getPlayServicesStatus();
  // 支持Google service 才能获取到token
  if (status.isAvailable) {
    const fcmToken = await messaging().getToken();
    if (fcmToken) {
      ReactMoE.passFcmPushToken(fcmToken);
      await setFCMToken(fcmToken);
    }
  }
  // 请求推送权限
  ReactMoE.requestPushPermissionAndroid();
};

export interface VersionInfo {
  /** 版本 */
  appVersion: string;
  /** 下载地址 */
  downUrl: string;
  /** 提示文案 */
  levelContent: string;
  /** 1提示不强制,2不提示,3强更 */
  levelUp: 1 | 2 | 3;
  /** 图片url */
  popBack: string;
  // versionCode: 236;
}

export const checkVersion = () => {
  return http.post<
    {channelId: string; versionCode: string; signInfo: string},
    VersionInfo
  >('app/version/check', {
    channelId: globalStore.channel,
    packageInfo: undefined,
    versionCode: VERSION_CODE,
  });
};

export interface PopInfo {
  createTime: number;
  id: number;
  popImg: string;
  popUrl: string;
  /** 0开启1关闭 */
  status: 0 | 1;
}

export const checkPop = () => {
  return http.post<null, PopInfo>('app/sysPop/check');
};
export interface IListEle {
  popImg: string;
  popUrl: string;
  /** 0开启1关闭 */
  status: 0 | 1;
  title?: string;
}
export const checkPops = () => {
  return lottoHttp.post<null, IListEle[]>('app/sysPop/checkList');
};

export const dailyRecord = () => {
  if (globalStore.isWeb) {
    http
      .post('app/user/daily/record', {
        deviceId: globalStore.visitor,
      })
      .then();
  } else if (globalStore.isAndroid) {
    DeviceInfo.getAndroidId().then((androidId: string) => {
      http
        .post('app/user/daily/record', {
          deviceId: androidId,
        })
        .then();
    });
  }
};

export const tokenRoutes = [
  'GameWebView',
  'WebView',
  'Recharge',
  'Withdraw',
  'AddBank',
  'Vip',
  'Wallet',
  'Rebate',
  'Bets',
  'Transactions',
  'Transfer',
  'Notification',
  'NotificationDetail',
  'ProxyHome',
  'ProxyRules',
  'ProxyCommissionDetail',
  'ProxyTeamReport',
  'ProxyNewUser',
  'Invitation',
  'InvitationRecord',
  'InvitationRule',
  'Profile',
  'PromotionDetail',
  'Sports',
  'BetsShard',
];
